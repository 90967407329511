// DO NOT EDIT

import React from 'react'

export default () => (
  <>
  <h1>Rapid Urbanism Explorer: Installation</h1>
<p>This is version 0.3 of RUE. This version is an early prototype of the software.</p>
<p>This document gives an overview of the software architecture and gives instructions on installing
the software on your own server.</p>
<h2>Spatial Model</h2>
<p>For the spatial models, RUE uses Mobius Modeller, a client-side parametric modelling software in the
browser. The spatial model consist of four scripts; see below.</p>
<p>The input parameters for the spatial model are shown on the left hand side. The tabs along the top
of the 3D viewer show different model outputs.</p>
<h2>Financial Model</h2>
<p>For the financial models, RUE uses <a href="https://github.com/mengshukeji/Luckysheet">LuckySheet</a>, a
client-side spreadsheet.</p>
<p>In RUE 0.3, the financial model consist of multiple linked spreadsheets.
Values from the spatial model are inserted into specific cells in the spreadsheet, which then
triggers the spreadsheet calculations to be executed.</p>
<h2>Framework</h2>
<p>RUE03 is developed using the React framework, version 17.</p>
<p>The two main libraries that are used are Mobius Modeller for the spatial models, and Luckysheet for
the financial models.</p>
<h3>Mobius Modeller Integration</h3>
<p>Mobius Modeller was used to create the four scripts that generate the spatial models:</p>
<ul>
<li><em>City</em>: Generates the site polygon.</li>
<li><em>Neighbourhood</em>: Subdivides the site into a set of roads and partitions.</li>
<li><em>Tissue</em>: Subdivides partitions into lots.</li>
<li><em>Starter Buildings</em>: Generates 3D building massings on the lots.</li>
</ul>
<p>The Mobius scripts are developed in Mobius Modeller and are saved with a <code>.mob</code> file extension). The
scripts generate models in a geospatial information format (with a <code>.gi</code> file extension).The four
scripts are chained. The spatial model generated by the <em>City</em> script gets passed to the
<em>Neighbourhood</em> script; the spatial model generated by the <em>Neighbourhood</em> script gets passed to the
<em>Tissue</em> script; the spatial model generated by the <em>Tissue</em> script gets passed to the <em>Starter
Building</em> script;</p>
<p>In order to be able to execute the scripts inside RUE, they are exported as Javascript files (with a
<code>.js</code> extension). The files contain a main function, which when executed will generate both the
spatial model and the spatial data.</p>
<p>The four Mobius scripts (in <code>.js</code> format) can be found in the folder <code>/src/components/models/</code>
folder. The Geojson input data for the <em>City</em> script is also saved in the same folder.</p>
<p>The Mobius Javascript files require various Mobius libraries. The
source code for these Mobius libraries can be found in the <code>/src/mobius/</code> folder.</p>
<p>The spatial models are visualised as 3D models in a Mobius viewer, which is embedded within RUE in
an <em>iframe</em> html component. RUE communicates with the Mobius viewer using a simple <code>post-messsage</code>
API. The API allows RUE to load the different models, depending on the tab that is selected at the
top of the viewer.</p>
<p>For this version of RUE, Mobius Modeller version 0.6 was used.</p>
<ul>
<li><a href="https://design-automation.github.io/mobius-parametric-modeller-dev-0-6/">Mobius Modeller 0.6</a></li>
</ul>
<h3>Luckysheet Integration</h3>
<p>The financial model was developed as a Microsoft Excel spreadsheet, and then converted into the
LuckySheet format. The Excel file was manually converted, by loading the file into an <a href="https://mengshukeji.github.io/LuckyexcelDemo/">online
LuckySheet application</a>. The data was then extracted
and incorporated into the RUE application.</p>
<p>A bill of quantities is extracted from the spatial model and written into the <code>Q</code> spreadsheet.
These values are mostly infrastructure length and land use areas extracted from the spatial model.
Each time a new spatial model is generated, these values will be updated, which in turn will trigger
the financial model to be recalculated.</p>
<p>When this version of RUE was develop, the LuckySheet library was not yet available on the NPM
package manager. The source code for LuckySheet was therefore included, under the
<code>/public/luckysheet/</code> folder.</p>
<p>For more information on Luckysheet:</p>
<ul>
<li><a href="https://github.com/mengshukeji/Luckysheet">Luckysheet</a></li>
</ul>
<h2>Install and Build</h2>
<p>RUE03 is a client side application. The only server-side process is logging-in. If you decide to
install RUE03 on your own, then you should create your own login process.</p>
<p>The usual nodejs/npm tools are required. (npm is part of nodejs):</p>
<ul>
<li><a href="https://nodejs.org/en/">nodejs</a></li>
</ul>
<p>The project is set up to run on Amazon AWS, using the Amplify framework. Amazon Cognito is used for
logging in, so you need to create a Cognito user pool. For more information:</p>
<ul>
<li><a href="https://aws.amazon.com/cognito/">Amazon Cognito</a></li>
</ul>
<p>In order to create the <code>amplify</code> folder and contents, you need to install the Amplify CLI on your
local machine. For more information:</p>
<ul>
<li><a href="https://docs.amplify.aws/">Amplify</a></li>
<li><a href="https://docs.amplify.aws/cli/">Amplify CLI</a></li>
<li><code>npm install -g @aws-amplify/cli</code></li>
</ul>
<p>In addition, to generate Html files from Markdown files, <code>gulp.js</code> is used. You will therefore need
to install the Gulp CLI. For more information:</p>
<ul>
<li><a href="https://gulpjs.com/">Gulp CLI</a></li>
<li><code>npm install -g gulp-cli</code></li>
</ul>
<p>To clone the project:</p>
<ul>
<li><code>git clone https://github.com/rapidurbanism/RUE03</code></li>
</ul>
<p>To install, change to root folder, then:</p>
<ul>
<li><code>npm install</code></li>
</ul>
<p>To generate the amplify folder, change to root folder and run:</p>
<ul>
<li><code>amplify init</code></li>
</ul>
<p>To start dev server:</p>
<ul>
<li><code>npm start</code></li>
</ul>
<p>If you get the error message <code>Module not found: Can't resolve './aws-exports'</code>, then it probably
means that you have not run <code>amplify init</code>.</p>
<h2>Docs</h2>
<p>The docs are written as markdown files. To generate Html files from these Markdown files, <code>gulp.js</code>
is used. If you need to edit the docs, then you you should edit the markdown files and then
regenerate the Html files using <code>gulp</code>. You will therefore need to install the Gulp CLI. For more
information:</p>
<ul>
<li><a href="https://gulpjs.com/">Gulp CLI</a></li>
<li><code>npm install -g gulp-cli</code></li>
</ul>
<p>There are 4 markdown files.</p>
<p>In the root folder:</p>
<ul>
<li><code>README</code>: An overview.</li>
</ul>
<p>In the <code>markdown</code> foler:</p>
<ul>
<li><code>terms-conds</code>: Terms and conditions.</li>
<li><code>for-end-users</code>: User manual for end-users.</li>
<li><code>for-developers</code>: Help for developers (this file).</li>
</ul>
<p>After editing the markup files, you can re-generate the Html doc files like this:</p>
<ul>
<li><code>npm run docs</code></li>
</ul>
<h2>Direct Dependencies</h2>
<p>The two main dependencies are:</p>
<ul>
<li>Mobius Modeller: https://github.com/design-automation/mobius-parametric-modeller-dev-0-6</li>
<li>LuckySheet: https://github.com/mengshukeji/Luckysheet</li>
</ul>
<p>A list of direct dependencies were extracted using
<a href="https://www.npmjs.com/package/npm-license-crawler">NPM license crawler</a>, as follows:</p>
<ul>
<li><code>npm-license-crawler- --onlyDirectDependencies --csv rue_licenses.csv</code></li>
</ul>
<p>See <a href="./rue_licenses.csv">the output CSV file</a>.</p>
<p>The website links for the dependencies are as follows:</p>
<ul>
<li>https://github.com/ant-design/ant-design-charts</li>
<li>https://github.com/ant-design/ant-design-icons/tree/master/packages/icons-react</li>
<li>https://github.com/antvis/data-set</li>
<li>https://github.com/aws-amplify/amplify-js</li>
<li>https://github.com/gsoft-inc/craco</li>
<li>https://github.com/Doodle3D/clipper-js</li>
<li>https://github.com/formulajs/formulajs</li>
<li>https://github.com/gregberge/loadable-components</li>
<li>https://github.com/testing-library/jest-dom</li>
<li>https://github.com/testing-library/react-testing-library</li>
<li>https://github.com/testing-library/user-event</li>
<li>https://github.com/DefinitelyTyped/DefinitelyTyped</li>
<li>https://github.com/ant-design/ant-design</li>
<li>https://github.com/aws-amplify/amplify-js</li>
<li>https://github.com/ant-design/babel-plugin-import</li>
<li>https://github.com/alibaba/BizCharts</li>
<li>https://github.com/gka/chroma.js</li>
<li>https://github.com/omichelsen/compare-versions</li>
<li>https://github.com/FormAPI/craco-less</li>
<li>https://github.com/cytoscape/cytoscape.js</li>
<li>https://github.com/d3/d3-delaunay</li>
<li>https://github.com/d3/d3-polygon</li>
<li>https://github.com/d3/d3-voronoi</li>
<li>https://github.com/eligrey/FileSaver.js</li>
<li>https://github.com/Stuk/jszip</li>
<li>https://github.com/webpack-contrib/less-loader</li>
<li>https://github.com/less/less.js</li>
<li>https://github.com/josdejong/mathjs</li>
<li>https://github.com/streamich/memfs</li>
<li>https://github.com/mobxjs/mobx-react</li>
<li>https://github.com/mobxjs/mobx</li>
<li>https://github.com/BenjaminVanRyseghem/numbro</li>
<li>https://github.com/proj4js/proj4js</li>
<li>https://github.com/react-csv/react-csv</li>
<li>https://github.com/facebook/react</li>
<li>https://github.com/supremetechnopriest/react-idle-timer</li>
<li>https://github.com/goldenyz/react-perfect-scrollbar</li>
<li>https://github.com/ReactTraining/react-router</li>
<li>https://github.com/facebook/create-react-app</li>
<li>https://github.com/facebook/react</li>
<li>https://github.com/styled-components/styled-components</li>
<li>https://github.com/mrdoob/three.js</li>
<li>https://github.com/Microsoft/TypeScript</li>
<li>https://github.com/jashkenas/underscore</li>
</ul>

  </>
)